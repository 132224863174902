import React, { useCallback, useState } from 'react';
import { backfillSubscriptionIds, backfillSubscriptionMeta, backfillSubscriptionScopes, backfillSubscriptionUserLinks } from '@src/utils/mmc-api/typedApi';

const DeveloperView = () => {

    const [bfSubUserLinkLoading, setBfSubUserLinkLoading] = useState(false);
    const onBfSubUserLink = useCallback(() => {
        if (!bfSubUserLinkLoading) {
            setBfSubUserLinkLoading(true);
            backfillSubscriptionUserLinks().then((f) => {
                alert(f.message);
            }).finally(() => { 
                setBfSubUserLinkLoading(false);
            });
        }
    }, [bfSubUserLinkLoading])

    const [bfSubMetaLoading, setBfSubMetaLoading] = useState(false);
    const onBfSubMeta = useCallback(() => {
        if (!bfSubUserLinkLoading) {
            setBfSubMetaLoading(true);
            backfillSubscriptionMeta().then((f) => {
                alert(f.message);
            }).finally(() => { 
                setBfSubMetaLoading(false);
            });
        }
    }, [bfSubUserLinkLoading])

    const [bfSubIdsLoading, setBfSubIdsLoading] = useState(false);
    const onBfSubIds = useCallback(() => {
        if (!bfSubUserLinkLoading) {
            setBfSubIdsLoading(true);
            backfillSubscriptionIds().then((f) => {
                alert(f.message);
            }).finally(() => { 
                setBfSubIdsLoading(false);
            });
        }
    }, [bfSubUserLinkLoading])

    const [bfSubScopesLoading, setBfSubScopesLoading] = useState(false);
    const onBfSubScopes = useCallback(() => {
        if (!bfSubScopesLoading) {
            setBfSubScopesLoading(true);
            backfillSubscriptionScopes().then((f) => {
                alert(f.message);
            }).finally(() => { 
                setBfSubScopesLoading(false);
            });
        }
    }, [bfSubScopesLoading])

    return (
        <div className='container'>
            <button onClick={onBfSubUserLink} className="w-nav-brand sign-in-badge">
                {bfSubUserLinkLoading ? 'Loading...' : 'Backfill Subscription User Link'}
            </button>

            <button onClick={onBfSubMeta} className="w-nav-brand sign-in-badge">
                {bfSubMetaLoading ? 'Loading...' : 'Backfill Subscription Meta'}
            </button>

            <button onClick={onBfSubIds} className="w-nav-brand sign-in-badge">
                {bfSubIdsLoading ? 'Loading...' : 'Backfill Subscription IDs'}
            </button>

            <button onClick={onBfSubScopes} className="w-nav-brand sign-in-badge">
                {bfSubIdsLoading ? 'Loading...' : 'Backfill Subscription Scopes'}
            </button>
        </div>
    )
};

export default DeveloperView;
